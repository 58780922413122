<template>
	<div class="loadingContainer" v-if="enabled">
		<h1 class="loader">Loading...</h1>
	</div>
</template>


<script>
	export default {
		name: "LoadingScreen",
		props: {
			enabled: {
				type: Boolean,
				default: false
			}
		}
	}
</script>


<style lang="scss" scoped>
	.loadingContainer {
		display: flex;
		align-items: center;
		justify-content: center;

		position: fixed;
	    padding: 0;
	    margin: 0;
	    top: 0;
	    left: 0;
	    width: 100vw;
	    height: 100vh;
	    z-index: 99999;

	    backdrop-filter: blur(3px);
	    background: rgba(50, 50, 50, 80%);
	    color: var(--text);
	}


	.loader, .loader:before, .loader:after {
	  border-radius: 50%;
	  width: 2.5em;
	  height: 2.5em;
	  -webkit-animation-fill-mode: both;
	  animation-fill-mode: both;
	  -webkit-animation: load7 1.8s infinite ease-in-out;
	  animation: load7 1.8s infinite ease-in-out;
	}
	.loader {
	  color: #ffffff;
	  font-size: 10px;
	  margin: 80px auto;
	  position: relative;
	  text-indent: -9999em;
	  -webkit-transform: translateZ(0);
	  -ms-transform: translateZ(0);
	  transform: translateZ(0);
	  -webkit-animation-delay: -0.16s;
	  animation-delay: -0.16s;
	}
	.loader:before,
	.loader:after {
	  content: '';
	  position: absolute;
	  top: 0;
	}
	.loader:before {
	  left: -3.5em;
	  -webkit-animation-delay: -0.32s;
	  animation-delay: -0.32s;
	}
	.loader:after {
	  left: 3.5em;
	}
	@-webkit-keyframes load7 {
	  0%,
	  80%,
	  100% {
	    box-shadow: 0 2.5em 0 -1.3em;
	  }
	  40% {
	    box-shadow: 0 2.5em 0 0;
	  }
	}
	@keyframes load7 {
	  0%,
	  80%,
	  100% {
	    box-shadow: 0 2.5em 0 -1.3em;
	  }
	  40% {
	    box-shadow: 0 2.5em 0 0;
	  }
	}
</style>