<template>
	<div class="main">
		<ProfileSetup v-if="ready && user !== null" class="form" :user="user" />
	</div>
</template>


<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import ProfileSetup from "@/components/ProfileSetup";

export default {
	name: "ProfileSetupView",
	components: { ProfileSetup },
	data() {
		return {
			editing: this.$route.query.editing,
			ready: false,
			user: Object
		};
	},
	mounted() {
		this.user = this.userStore.user;
		this.ready = true;
	},
	computed: {
		...mapStores(useUserStore),
	}
}
</script>


<style lang="scss" scoped>
	.main {
		@include gradient;

		height: 100vh;

		display: grid;
		grid-template-columns: 0.8fr 1.4fr 0.8fr;
		grid-template-rows: 0.3fr auto 0.3fr;
		gap: 0px 0px;
		grid-template-areas:
			". . ."
			". form ."
			". . .";

		.form {
			@include center;
			@include box-shadow();
			grid-area: form;
			width: 100%;
		}

		@include respond-to('medium') {
			grid-template-columns: 0.3fr 1.4fr 0.3fr;
		}
		@include respond-to('small') {
			grid-template-columns: 0.1fr 1.4fr 0.1fr;
		}
	}
</style>