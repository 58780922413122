<template>
  <div>
    <img
      class="closeButton"
      src="@/assets/icons/close-button.svg"
      @click="closeBtnHandler"
    />
    <h3>
      Account is Successfuly deleted
    </h3>
    <p>
      Thank you for using <span>Efan.gg</span>.
      <br />
      You are going to be redirected in {{ countdown }} seconds.
    </p>
    Goodbye :)
  </div>
</template>

<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "Goodbye",
  prop: [],
  data() {
    return {
      countdown: 5,
    };
  },
  mounted() {
    const timer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        clearInterval(timer);
        this.userStore.$reset()
        this.$router.push({ path: "/" });
      }
    }, 1000);
  },
  unmounted() {
    clearInterval(timer);
  },
  methods: {
    closeBtnHandler() {
      this.$emit("goodbyeClosed");
    },
  },
  computed: {
    ...mapStores(useUserStore)
  }
};
</script>

<style lang="scss" scoped>
div {
  .closeButton {
    @extend .pointer;
    @extend .noselect;
    @extend .nodrag;
    position: absolute;
    top: 10px;
    left: 10px;

    &:hover {
      @include popup();
    }
  }

  h3 {
    @include side-component-title;
    margin: 0;
    // margin-top: 50px;
    margin-bottom: 30px;
    font-size: 20px !important;
    font-weight: bold !important;
    color: white !important;
  }
  p {
    margin-top: 20px;

    span {
      color: #fd8e00;
      font-weight: bold;
    }
  }
}
</style>
