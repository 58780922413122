<template>
  <div class="container">
    <div class="alert" v-if="error.length > 0">
      <h1>Alert</h1>
      <h3>{{ error }}</h3>
    </div>

    <LoadingScreen :enabled="!ready" />

    <h1>Set up your profile</h1>

    <!-- Two radio buttons for selecting type of profile -->
    <div class="typeContainer">
      <div>
        <label class="labelContainer">
          <input
            type="radio"
            id="typePerson"
            value="person"
            v-model="model.type"
            :checked="model.type === 'person'"
          />
          <div>
            <img src="@/assets/icons/users.svg" />
            Person
          </div>
        </label>

        <label class="labelContainer">
          <input
            type="radio"
            id="typeCompany"
            value="company"
            v-model="model.type"
            :checked="model.type === 'company'"
          />
          <div>
            <img src="@/assets/icons/house.svg" />
            Company
          </div>
        </label>
      </div>
      <label v-if="showDelete" class="labelContainer">
        <input
          type="radio"
          id="typeDelete"
          value="delete"
          v-model="model.type"
          :checked="model.type === 'delete'"
          @click="promtDeleteUser()"
        />
        <div>
          <img src="@/assets/icons/trash-2.svg" />
          Delete User
        </div>
      </label>
    </div>

    <!-- Uploading a profile pic -->
    <div class="profileImgContainer">
      <ImageUpload
        preview="profile"
        :imageUrl="model.profile_img_url"
        @changed="profileImgChanged"
      />
      <h3>{{ text[0] }}</h3>
    </div>

    <!-- Inputting info like name, nickname, pc_specs and bio -->
    <input
      type="text"
      autocomplete="name"
      :placeholder="text[1]"
      v-model="model.name"
      maxlength="50"
      pattern="[1-9]+"
      class="phStyle"
    />

    <textarea
      v-if="model.type === 'person' || model.type === 'delete'"
      rows="4"
      :placeholder="text[5]"
      v-model="model.pc_specs"
      maxlength="400"
      class="phStyle"
    >
    </textarea>

    <textarea
      rows="4"
      :placeholder="text[2]"
      v-model="model.bio"
      maxlength="400"
      class="phStyle"
    ></textarea>

    <!-- Inputting location info -->
    <div class="locationContainer">
      <h3>{{ text[3] }}</h3>
      <img
        v-if="!showLocation"
        @click="toggleLocation"
        src="@/assets/icons/plus-circle.svg"
      />
      <input
        v-else
        ref="locationInput"
        class="locationInput phStyle"
        type="text"
        :placeholder="text[3]"
        v-model="model.location"
        maxlength="40"
      />
    </div>

    <!-- Uploading a cover image for their profile -->
    <div class="coverImgContainer">
      <ImageUpload
        preview="cover"
        :imageUrl="model.cover_img_url"
        @changed="coverImgChanged"
      />
      <h3>{{ text[4] }}</h3>
    </div>

    <div class="buttonContainer">
      <button @click="save">SAVE</button>
      <button @click="redirect">SKIP</button>
    </div>
    <transition name="fadeModal">
      <div
        v-if="showDeleteUserPopup"
        class="accountDeletionModal"
        @click.self="
          showDeleteUserPopup = false;
          model.type = 'person';
        "
      ></div>
    </transition>
    <transition name="fade">
      <form v-if="showDeleteUserPopup" @submit.prevent="handleDeleteUser">
        <div v-if="showLoadingState">
          <img
            class="closeButton"
            src="@/assets/icons/close-button.svg"
            @click="
              showDeleteUserPopup = false;
              showLoadingState = false;
              model.type = 'person';
            "
          />
          <Loader :loading="showLoadingState" />
        </div>
        <Goodbye
          v-else-if="showLoadingState === false && showGoodbye"
          @goodbyeClosed="
            showDeleteUserPopup = false;
            showLoadingState = false;
            model.type = 'person';
          "
        />
        <div v-else>
          <img
            class="closeButton"
            src="@/assets/icons/close-button.svg"
            @click="
              showDeleteUserPopup = false;
              showLoadingState = false;
              model.type = 'person';
            "
          />
          <h3>
            Account Deletion
          </h3>
          <p>
            <span>Warning!</span> Account deletion is irreversible.
            <br />
            Once an account is deleted you can't use it anymore.
          </p>
          <input
            type="email"
            v-model="emailInp"
            placeholder="Insert your email"
            required
          />
          <input
            type="password"
            v-model="passwordInp"
            placeholder="Insert your password"
            required
          />
          <input type="submit" value="Delete Account" />
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
// Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";
// Components
import Loader from "./Loader.vue";
import Goodbye from "./Goodbye.vue";
import ImageUpload from "@/components/ImageUpload";
import LoadingScreen from "@/components/LoadingScreen";

export default {
  name: "ProfileSetup",
  components: { ImageUpload, LoadingScreen, Goodbye, Loader },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      model: {
        type: "person",
        name: "",
        pc_specs: "",
        bio: "",
        location: "",
        profile_img: false, // FormData object when filled out
        cover_img: false, // FormData as well
        profile_img_url: null,
        cover_img_url: null,
      },
      passwordInp: "",
      emailInp: "",
      showDelete: true,
      showLocation: false,
      ready: true,
      showDeleteUserPopup: false,
      showLoadingState: false,
      showGoodbye: false,
      countdown: 5,
      error: "",
    };
  },
  mounted() {
    if(this.$route.fullPath === "/setup") {
      this.showDelete = false;
    }
    if (this.user !== false) {
      // const user = this.$formatter.replaceEmpty(this.user);

      this.model.type = this.user.type;
      this.model.name = this.user.name;
      this.model.pc_specs = this.user.pc_specs;
      this.model.bio = this.user.bio;
      this.model.location = this.user.location;
      this.model.profile_img_url = this.user.img_url;
      this.model.cover_img_url = this.user.cover_img_url;

      this.showLocation = this.model.location !== "" || this.model.location != null;
    }
  },
  methods: {
    toggleLocation() {
      this.showLocation = true;
      setTimeout(() => {
        this.$refs.locationInput.focus();
      }, 100);
    },
    profileImgChanged(data) {
      this.model.profile_img = data;
    },
    coverImgChanged(data) {
      this.model.cover_img = data;
    },
    promtDeleteUser() {
      this.showDeleteUserPopup = true;
    },
    async handleDeleteUser() {
      if (this.passwordInp.length < 6 || this.passwordInp.length > 255) {
        this.error = "Password should be between 6 and 255 characters.";
        return;
      }
      if (this.emailInp.length <= 0 ) {
        this.error = "Email can't be empty.";
        return;
      }

      this.showLoadingState = true;

      // GetUserEmail
      const model = {
        email: this.emailInp,
        password: this.passwordInp,
      };

      const loginRes = await this.$api.login(model);
      
      if (loginRes.user) {
        const resDel = await this.$api.deleteUser(this.userStore.user.id, model, this.userStore.accessToken);

        if(resDel.success) {
          this.showGoodbye = true;
          this.error = "";
          this.showLoadingState = false;
        }
        else {
          this.error = "Something went wrong!"
          this.showLoadingState = false;
        }
      } else {
        this.showLoadingState = false;
        this.error = "Provided password is wrong!";
      }
    },
    async save() {
      // Validate all user inputs
      if (!["person", "company"].includes(this.model.type)) {
        this.ready = true;
        return;
      }
        
      if (this.model.profile_img !== false) {
        let response = await this.$api.uploadImage(this.model.profile_img, this.userStore.accessToken);

        if (response.success === true) {
          this.model.profile_img_url = response.url;
          this.userStore.user.img_url = response.url;
        }
      }

      if (this.model.cover_img !== false) {
        let response = await this.$api.uploadImage(this.model.cover_img, this.userStore.accessToken);

        if (response.success === true) {
          this.model.cover_img_url = response.url;
          this.userStore.user.cover_img_url = response.url;
        };
      }

      const { cover_img, profile_img, ...userSettings } = this.model;
      await this.$api.updateAndReturnUser(userSettings, this.userStore.accessToken)
        .then(user => {
          if(user.error) {
            this.error = user.error.message;
          } else {
            this.userStore.updateUserInfo(user);
            this.redirect();
          }
        });
    },
    async redirect() {
      if (this.$route.query.editing == "true") {
        let redirectPath = this.$store.getLoginRedirect();
        const getHash = await this.$api.getHash(this.userStore.user.id, this.userStore.accessToken);
        let path = '';
        if(getHash.success) {
          const hash = getHash.hash.hash_email;
          path = `/user/${this.userStore.user.username}-${hash}`;
        } else {
          this.$router.push({ path: path });
        }

        this.$router.push({
          path: redirectPath
            ? redirectPath
            : path,
        });
      } else {
        this.$router.push({ path: "/tags", query: { f: true } });
      }
    }
  },
  computed: {
    ...mapStores(useUserStore),
    text() {
      let person = [
        "Choose your profile photo",
        "Write your first and last name.",
        "Tell us something about you.",
        "Add your location",
        "Choose a cover photo",
        "Write about your PC specs."
      ];
      let company = [
        "Choose a profile photo",
        "Company Title",
        "Public Name",
        "Write about your company",
        "Add your location",
        "Choose a cover photo",
      ];
      return this.model.type === "person" ? person : company;
    },
  }
};
</script>

<style lang="scss" scoped>
.container {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 15px;
  max-width: 600px;
  min-width: 300px;
  background-color: var(--dark);
  border-radius: 15px;
	font-family: 'Roboto', sans-serif;

  .alert {
    @include center;
    @include box-shadow;

    animation: fadeIn 0.7s, popup 0.7s, rotate 0.4s;

    position: fixed;
    top: 10%;
    right: 10%;
    z-index: 9999;

    background-color: var(--orange);
    border-radius: 15px;
    padding: 10px;

    h1 {
      color: #242326;
      font-size: 1.5em;
    }
    h3 {
      color: #242326;
      font-size: 1.2em;
    }
  }

  & > * {
    margin-bottom: 20px;
  }
  h1 {
    width: 10em;
    margin: 0 auto;
    text-align: center;
    color: var(--light);
  }
  h3 {
    margin: 0;
    font-size: 1em;
    font-weight: 400;
    color: var(--medium);
  }
  .typeContainer {
    display: flex;
    justify-content: space-between;
    .labelContainer {
      @extend .pointer;
      @extend .noselect;
      @extend .nodrag;
      display: inline-block;
      margin-right: 10px;

      input {
        opacity: 0;
      }
      div {
        padding: 10px;
        background-color: var(--light);
        border-radius: 10px;

        img {
          width: 1em;
          height: 1em;
        }
      }

      input:checked ~ div {
        @include gradient;
      }
    }
  }
  .profileImgContainer {
    text-align: center;
  }
  .locationContainer {
    display: flex;
    align-items: center;

    * {
      display: inline-block;
    }
    img {
      @extend .pointer;
      margin-left: 10px;

      &:hover {
        @include popup(1.1);
      }
    }
    .locationInput {
      @include remove-outline;
      @include text-input;  
      padding-top: 5px;
      padding-bottom: 5px;
      margin-left: 10px;
      animation: fadeIn 0.3s, popup 0.3s;
    }
  }
  .coverImgContainer {
    width: 100%;
    text-align: center;
  }
  .buttonContainer {
    text-align: center;
    button {
      @include button;
      @include gradient;
      margin: 10px;
      font-size: 1.2em;
      font-weight: 200;
    }
  }

  input[type="text"],
  textarea {
    @include remove-outline;
    @include text-input;
    display: block;
    padding: 15px;
    width: 60%;
  }
  textarea {
    @include scrollbars;
    resize: none;
  }
}
.phStyle::placeholder {
  color: rgb(237, 237, 237) !important;
  opacity: .7;
  font-weight: 400;
}

// Animations for form / popup
.fade-enter {
  opacity: 0;
  margin-top: 100px;
}

.fade-enter-active {
  transition: all 0.6s;
}

// .fade-leave {
// }

.fade-leave-active {
  transition: all 0.6s;
  margin-top: 100px;
  opacity: 0;
}

// Animation for modal
.fadeModal-enter {
  opacity: 0;
}

.fadeModal-enter-active {
  transition: all 0.6s;
}

.fadeModal-leave-active {
  transition: all 0.6s;
  opacity: 0;
}

// Popup style
.accountDeletionModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.4);
}

form {
  @include side-component;
  @include box-shadow;

  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  z-index: 9999;

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 600px;
  min-height: 300px;
  height: auto;
  text-align: center;
  background-color: var(--dark);
  padding: 25px;

  @include respond-to("small") {
    width: 95vw;
  }

  .closeButton {
    @extend .pointer;
    @extend .noselect;
    @extend .nodrag;
    position: absolute;
    top: 10px;
    left: 10px;

    &:hover {
      @include popup();
    }
  }

  h3 {
    @include side-component-title;
    margin: 0;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 20px !important;
    font-weight: bold !important;
    color: white !important;
  }
  p {
    margin-top: 20px;

    span {
      color: #fd8e00;
      font-weight: bold;
    }
  }

  .error {
    font-size: 0.7em;
  }

  input {
    @include text-input;

    width: 80%;
    padding: 7px;
    margin: 0 auto;
    margin-bottom: 10px;
    border: none;

    display: block;
  }
  input[type="submit"] {
    @extend .pointer;

    width: 60%;
    border: none;
    background-color: var(--orange);

    &:hover {
      @include popup(1.02);
    }
  }
  .error {
    margin-bottom: 0;
    padding: 0;
    color: white;
  }
}
</style>
